import React from 'react';
// import './App.css';
import './Nav.css';
// import {Link} from 'react-router-dom';

function Nav() {
    return (
      <div>
          <h2>Resume Builder Page</h2>
          <script src="../test.js"></script>
      </div>
    );
  }

export default Nav;